@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:wght@700&display=swap');
body{
    font-family: 'Libre Baskerville', 'Cardo' ,cursive, ;
}

